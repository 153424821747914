import * as React from 'react'
import { Link } from 'gatsby'
import Layout from '/src/components/layout'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileScreenButton, faGlobe, faUserGear } from '@fortawesome/free-solid-svg-icons'

const SupportWebbsidaPage = () => {
	return (
		<Layout pageTitle="Support kring Brfify-webbsida">

			<p className="intro">En Brfify-webbsida besöks enkelt av vem som helst från en dator, mobil eller surfplatta.</p>


            <h2>Hur hittar jag webbsidan?</h2>
            <p>Om din fastighetsägare har valt att lista webbsidan publikt så kan du hitta den <Link to="/hitta">här</Link>. Annars behöver du få adressen av din fastighetsägare, vänligen kontakta dem.</p>

            <h2>Behöver jag logga in på webbsidan?</h2>
            <p>Nej, webbsidan kräver ingen inloggning. För att som boende se privat innehåll och få tillgång till alla funktioner behöver du använda <Link to="/support/app">Brfify-appen</Link>.</p>

            <h2>För vem är webbsidan?</h2>
			<p>Webbsidan är ett sätt att enkelt och snyggt tillgängliggöra publik informaiton för allmänheten, exempelvis boende, mäklare, potentiella köpare/hyresgäster med mera. Boende rekommenderas dock att i huvudsak använda sig av <Link to="/support/app">Brfify-appen</Link> eftersom det är där alla funktioner och privat innehåll finns.</p>

		</Layout>
	)
}

export default SupportWebbsidaPage